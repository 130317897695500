import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import commentsIcon from '@fortawesome/fontawesome-free/svgs/solid/comment-dots.svg';

export default class Comments extends Plugin {
	init() {
		const editor = this.editor;

		editor.ui.componentFactory.add('comments', (locale) => {
			const view = new ButtonView(locale);

			view.set({
				label: 'Insert image',
				icon: commentsIcon,
				tooltip: true,
			});

			// Callback executed once the image is clicked.
			view.on('execute', () => {
				const imageUrl = prompt('Image URL');

				editor.model.change((writer) => {
					const imageElement = writer.createElement('imageBlock', {
						src: imageUrl,
					});

					// Insert the image in the current selection location.
					editor.model.insertContent(imageElement, editor.model.document.selection);
				});
			});

			return view;
		});
	}
}
